.hub {
  .markdown {
    width: 100%;
    border: 1px solid #eee;
    border-radius: 10px;
    margin: 15px 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 35px;
  }
}
